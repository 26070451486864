import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import { Theme } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    backgroundColor: blue[900],
  },
  container: {
    margin: `${theme.spacing(10)}px auto`,
    '& .welcomeText': {
      textAlign: 'center',
      color: theme.palette.common.white,
    },
  },
  paper: {
    minHeight: 294,
    width: 500,
    borderRadius: theme.spacing(2),
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    '& .MuiInputBase-root': {
      marginBottom: theme.spacing(1),
    },
  },
  titleBar: {
    textAlign: 'center',
    lineHeight: `${theme.spacing(5)}px`,
    backgroundColor: theme.palette.grey[200],
    borderRadius: '16px 16px 0px 0px',
    marginBottom: theme.spacing(1),
  },
  form: {
    padding: `0px ${theme.spacing(2)}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    flexGrow: 1,
  },

  alreadyLoggedIn: {
    margin: '50px 0',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    height: 300,
  },
}));

export default useStyles;
