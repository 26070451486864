import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

interface WaveProps {
  waveBottom?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  wavePosition: {
    position: 'fixed',
    width: '100vw',
    zIndex: 0,
    bottom: '25%',
    transition: `bottom 1s ease-in-out`,
    [theme.breakpoints.down('md')]: {
      bottom: '50%',
    },
  },
  moveBottom: {
    transition: `bottom 1s ease-in-out`,
    bottom: 0,
    [theme.breakpoints.down('md')]: {
      bottom: '35px',
    },
  },
  parallax: {
    animation: `$moveForever 1s linear infinite`,
    '&:nth-child(1)': {
      animationDelay: '-30s',
      animationDuration: '15s',
    },
    '&:nth-child(2)': {
      animationDelay: '-15s',
      animationDuration: '30s',
    },
    '&:nth-child(3)': {
      animationDelay: '-30s',
      animationDuration: '45s',
    },
  },
  '@keyframes moveForever': {
    '0%': {
      transform: 'translate(-90px, 0%)',
    },
    '100%': {
      transform: 'translate(85px, 0%)',
    },
  },
}));

export const Wave: React.FC<WaveProps> = props => {
  const classes = useStyles({});
  const { waveBottom } = props;
  return (
    <div className={`${classes.wavePosition} ${waveBottom && classes.moveBottom}`}>
      <svg aria-hidden="true" focusable="false">
        <linearGradient id="wave-gradient" x2="0" y2="1">
          <stop offset="0%" stopColor="rgba(255,255,255,.2)" />
          <stop offset="10%" stopColor="rgba(255,255,255,.1)" />
          <stop offset="20%" stopColor="rgba(255,255,255,.05)" />
          <stop offset="40%" stopColor="rgba(36,26,171,.0)" />
        </linearGradient>
      </svg>
      <svg className="editorial" viewBox="0 24 150 40" preserveAspectRatio="none">
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0
    58-6 88-6s
    58 6 88 6
    58-6 88-6
    58 6 88 6
    v44h-352z"
          />
        </defs>
        <g className={classes.parallax}>
          <use xlinkHref="#gentle-wave" x="50" y="0" fill="url(#wave-gradient)" />
          <use xlinkHref="#gentle-wave" x="50" y="3" fill="url(#wave-gradient)" />
          <use xlinkHref="#gentle-wave" x="50" y="5" fill="url(#wave-gradient)" />
        </g>
      </svg>
    </div>
  );
};
