import React, { Suspense, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';

import LoadingComp from '../../components/Loader/LoadingComp';
import { Button, Paper, Typography } from '@material-ui/core';
import useStyles from './styles';
import { useFirebase } from 'react-redux-firebase';
import { User } from 'firebase';
import ErrorBoundary from '../../components/ErrorBoundary';
import { Wave } from '../../components/Common/Wave';

const AuthPage: React.FC<RouteComponentProps> = props => {
  const classes = useStyles({});

  const firebase = useFirebase();
  const [currentUser, setCurrentUser] = useState<User>(null);

  useEffect(() => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      setCurrentUser(currentUser);
      props.navigate('/secure/projects');
    }
  }, [firebase.auth().currentUser, firebase]);

  const onLogout = () => {
    firebase.auth().signOut();
    setCurrentUser(null);
  };

  return (
    <div className={classes.root}>
      <Wave />
      <div className={classes.container}>
        <Typography className={'welcomeText'} variant={'h6'} gutterBottom>
          Welcome to Feedr
        </Typography>
        <Paper className={classes.paper}>
          {// ? For Invite Landing Page, you need to log the user out
          props.location.pathname !== '/auth/invite' && currentUser ? (
            <div className={classes.alreadyLoggedIn}>
              <Typography>You're already logged in as</Typography>
              <Typography variant={'h5'}>{currentUser?.displayName}</Typography>
              <Button variant={'contained'} color={'primary'} onClick={onLogout}>
                Log Out
              </Button>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={() => props.navigate('/secure')}
              >
                Go to Dashboard
              </Button>
            </div>
          ) : (
            <Suspense fallback={<LoadingComp />}>
              <ErrorBoundary>{props.children}</ErrorBoundary>
            </Suspense>
          )}
        </Paper>
      </div>
    </div>
  );
};

export default AuthPage;
